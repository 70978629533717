import { compose } from 'underscore';
import { apiFetchMobileNumber, apiFetchMobileCountries } from '../api';

export const FETCH_MOBILE_NUMBER = 'REQUEST_CALLBACK.FETCH_MOBILE_NUMBER';
export const FETCH_COUNTRIES = 'REQUEST_CALLBACK.FETCH_COUNTRIES';

export const fetchMobileNumberComplete = (mobileNumber) => ({
  type: FETCH_MOBILE_NUMBER,
  mobileNumber,
});

export const fetchCountriesComplete = (countries) => ({
  type: FETCH_COUNTRIES,
  countries,
});

export const fetchMobileNumber = () => (dispatch) => {
  dispatch(apiFetchMobileNumber).then(compose(dispatch, fetchMobileNumberComplete));
};

export const fetchMobileCountries = () => (dispatch) => (
  apiFetchMobileCountries().then(compose(dispatch, fetchCountriesComplete))
);

const initialState = {
  mobileNumber: undefined,
  loadedNumber: false,
  countries: [],
  loadedCountries: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_MOBILE_NUMBER:
      return {
        ...state,
        loadedNumber: true,
        mobileNumber: action.mobileNumber,
      };
    case FETCH_COUNTRIES:
      return {
        ...state,
        loadedCountries: true,
        countries: action.countries,
      };
    default:
      return state;
  }
};
